@import "../theme.scss";

.header {
    width: calc(100% - 70px);
    max-width: $content-width;
    margin: 35px auto;
    display: flex;
    justify-content: space-between;

    .mutedText{
        color: $muted;
    }

    .header-panel {
        display: flex;
    }

    img {
        height: 35px;
    }

    // @media screen and (max-width: $mobile-width) {
    //     justify-content: center;
    //     flex-wrap: wrap;
    //     .header-panel {
    //         margin-top: 20px;
    //         width: 100%;
    //         justify-content: center;
    //     }
    // }
}
