@import "../theme.scss";

.authContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 100vw;
    position: relative;
    @media screen and (max-width: $tablet-width) {
        margin-left: 40px;
        width: calc(100vw - 80px);
    }
    // margin: 0 auto;
    form {
        width: calc(100% - 150px);
        max-width: $form-width;

        @media screen and (max-width: $tablet-width) {
            width: calc(100% - 40px);
            max-width: 100%;
        }
    }

    .tempWrapper {
        width: $content-width;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        justify-items: center;
        padding-left: 40px;
        padding-right: 40px;

        @media screen and (max-width: $content-width * 0.8) {
            grid-template-columns: 2fr 3fr;
            justify-items: end;
            align-items: start;
        }

        @media screen and (max-width: $tablet-width) {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            justify-items: center;
            align-items: start;
            padding-left: 0px;
            padding-right: 4px;
        }
    }
}
