@import "../theme.scss";

.search {
    width: 100%;

    input {
        width: 100%;
        border: none;

        font-size: 18px;
        margin: 13px 0 13px 13px;

        color: $primary;
    }
}
