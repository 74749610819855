@import "../theme.scss";

.item-container {
    width: calc(100% - 50px);
    max-width: $content-width + 20px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $mobile-width) {
        justify-content: center;
    }
}
