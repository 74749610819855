@import "../theme.scss";

.button {
    border: none;
    background: none;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    &.main {
        background: $primary;
        border-radius: 5000px;
        color: white;
    }

    img {
        height: 12px;
        margin-right: 5px;
    }

    cursor: pointer;
}