@import "../theme.scss";

.input_item {
    width: 100%;
    position: relative;
    display: block;

    .react-datepicker-wrapper, .react-datepicker__input-container {
        display: block;
    }

    .error {
        position: absolute;
        right: 0;
        top: 0;
        color: red;
        font-size: 0.95em;
    }

    &.short {
        input {
            width: calc(100% - 75px);
        }
    }

    .remove-button {
        position: absolute;
        border: none;
        background: none;
        right: 0;
        top: 20px;
        cursor: pointer;
    }
}
