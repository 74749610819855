@import "../theme.scss";

.textarea_item {
    width: 100%;
    position: relative;

    textarea {
        width: calc(100% - 30px);
        border: none;
        background-color: rgba(black, 0.05);
        padding: 10px 15px;
        border-radius: 15px;
        height: 100px;
        resize: none;
        margin-top: 16px;
        margin-bottom: 20px;
    }

    .textarea-detail {
        font-size: 0.75em;
        position: absolute;
        bottom: 30px;
        right: 10px;
    }

    .error {
        position: absolute;
        right: 0;
        top: 0;
        color: red;
        font-size: 0.95em;
    }
}
