.block-container {
	margin: 1rem 0;

	h2 {
		margin: 1rem 0;
		font-size: 32px;
	}

	h3 {
		margin: 0.5rem 0;
	}

	p {
		margin: 0.75rem 0;
		line-height: 1.5;
		span {
			font-weight: 700;
		}
	}
}