@import "../theme.scss";

.hero {
    width: calc(100% - 70px);
    max-width: $content-width;
    margin: 40px auto;
    // display: flex;
    justify-content: space-between;

    .titles {
        h1 {
            font-size: 24px;
        }

        h4 {
            margin-top: 5px;
            font-size: 18px;
            opacity: 0.5;
        }

        flex-grow: 1000;
    }

    .body {
        display: flex;
        justify-content: flex-end;
    }

    @media screen and (max-width: $content-width) {
    }

    @media screen and (max-width: $mobile-width) {
        justify-content: center;
        flex-wrap: wrap;

        .titles {
            text-align: center;
            margin-bottom: 25px;

            h1 {
                font-size: 23px;
            }

            h4 {
                font-size: 13px;
            }
        }
    }
}
