@import "../theme.scss";

.expandable-container {
    $dim: 50px;
    position: relative;

    @media screen and (max-width: $tablet-width) {
        margin-top: 2rem;
    }

    .expandable {
        height: $dim;
        width: $dim;
        border: none;
        background: white;
        margin-left: 10px;
        padding: 0px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus {
            outline: none;
        }

        cursor: pointer;
        $spread: 2px;
        $blur: 20px;
        $opacity: 0.04;
        -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
    }

    .expanded {
        position: absolute;
        width: calc(100vw - 120px);
        max-width: 400px;
        background: white;
        top: 0;
        height: $dim;
        right: $dim;
        input {
            margin: 0px;
            padding: 0px;
            width: 100%;
            height: $dim;
        }
    }
}
