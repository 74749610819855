@import "../theme.scss";

.restaurants-wrapper {
    max-width: 380px;
    width: calc(80%);
    margin-top: 100px;
    justify-self: start;

    @media screen and (max-width: $tablet-width) {
        justify-content: center;
        margin-top: 0px;
    }

    @media screen and (max-width: $content-width * 0.8) {
        padding: 0;
    }
    .restaurant-list {
        background: white;
        $padding: 20px;

        // width: calc(100% - 80px);
        // max-width: 238px;

        margin-bottom: 20px;

        display: flex;
        flex-direction: column;
        $spread: 2px;
        $blur: 20px;
        $opacity: 0.04;

        -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);

        .restaurant-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .active {
                height: 10px;
                width: 10px;
                position: absolute;
                left: 15px;
                background-color: $primary;
                // display: inline-block;
                margin-right: 13px;
                border-radius: 1000px;
            }

            h3 {
                padding-left: 15px;
                display: inline-block;
            }

            .restaurant-tag {
                margin-left: 20px;
                padding: 0.25rem 0.75rem;
                border-radius: 10px;
                background-color: rgba($primary, 0.75);
                min-width: 55px;
                text-align: center;
                h3 {
                    color: $background;
                    padding-right: 5px;
                    padding-left: 5px;
                }
            }
            border: none;
            background: white;
            padding: 20px;
            text-align: left;
            cursor: pointer;

            // border-bottom: 1px solid rgba(black, 0.05);
            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: rgba($primary, 0.1);
            }

            .restaurant-thumbnail {
                width: 30px;
                height: 30px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 50%;
                display: inline-block;
                margin-left: 20px;
            }
        }
    }

    .restaurant-actions {
        $spread: 2px;
        $blur: 20px;
        $opacity: 0.04;

        -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);

        background: white;

        button {
            cursor: pointer;
            color: $primary;
            padding: 20px;
            border: none;
            background: none;
            width: 100%;
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
                height: 12px;
                margin-bottom: 2px;
            }
        }
    }
}
