@import "../theme.scss";

.opening-hours {
    display: flex;
    position: relative;
    justify-content: space-between;

    .time-slot {
        display: flex;
        margin-right: 50px;

        .input_item {
            input {
                border: none;
                width: 100%;
                background-color: rgba(black, 0.05);
                padding-top: 5px;
                padding-bottom: 5px;
            }
            margin-bottom: 10px;
            border-radius: 5px;
            max-width: 83px;
            &:first-child {
                margin-right: 10px;
            }
        }
        .error {
            border: 0.5px solid red;
        }
    }

    button.secondary {
        border: none;
        background: none;

        position: absolute;
        right: 0px;
        cursor: pointer;
    }

    button.remove-button {
        border: none;
        background: none;

        position: absolute;
        right: 0px;
        cursor: pointer;
    }
}
