@import "../theme.scss";

.select_item {
    width: 100%;
    position: relative;
    select {
        width: 100%;
        border: none;
        background-color: rgba(black, 0.05);
        padding: 10px 15px;
        border-radius: 5000px !important;
        margin-top: 16px;
        margin-bottom: 20px;
        -webkit-appearance: menulist-button;
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 6px;
    }
    
    select::-ms-expand {
        /* For IE10 */
        display: none;
    }

    .error {
        position: absolute;
        right: 0;
        top: 0;
        color: red;
        font-size: 0.95em;
    }
}
