@import "../theme.scss";

.drop_area {
    border: 2px dotted rgba(black, 0.15);
    padding: 20px;
    height: 120px;
    border-radius: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    cursor: pointer;

    margin-top: 15px;
    margin-bottom: 20px;

    position: relative;
    img {
        height: 100px;
        width: auto;
        border-radius: 5px;
    }
}
