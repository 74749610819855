@import "../theme.scss";

.form {
    background-color: white;
    $padding: 40px;

    width: calc(100% - 150px);
    max-width: $form-width;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;

    padding: $padding;
    $spread: 2px;
    $blur: 20px;
    $opacity: 0.04;

    -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
    -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
    box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);

    position: relative;

    @media screen and (max-width: $tablet-width) {
        padding: 20px;
        
    }

    .tabs {
        width: 100%;
        left: 0;
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: -45px;

        a {
            color: lighten($primary, 20);
            font-size: 27px;
            @media screen and (max-width: $tablet-width) {
                margin-top: 18px;
                font-size: 18px;
            }
            &.active {
                color: $primary !important;
            }
        }

        .label {
            color: $primary !important;
            @media screen and (max-width: $mobile-width) {
                font-size: 12px;
            }
        }
    }

    .submit-button {
        background: $primary;
        border-radius: 5000px;
        margin-top: 10px;
        padding: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        color: white;
    }

    .extra {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        a {
            color: gray;
        }
    }

    &.centered {
        margin: 100px auto;
    }

    .actions {
        position: absolute;
        border-radius: 0;
        // background: white;
        left: -70px;
        top: 0;
        display: flex;
        flex-direction: column;
        button {
            cursor: pointer;
            border-radius: 0;
            background: white;
            margin-top: 0;
            margin-bottom: 15px;
            padding: 20px;
            border: none;

            -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
            -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
            box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);

            .active-img {
                filter: invert(49%) sepia(42%) saturate(4789%) hue-rotate(331deg) brightness(108%) contrast(91%);
            }
            .unactive-img {
                filter: invert(37%) sepia(0%) saturate(2764%) hue-rotate(47deg) brightness(116%) contrast(80%);
            }
        }

        @media screen and (max-width: $tablet-width) {
            right: 0;
            top: -80px;
            flex-direction: row-reverse;
            button {
                margin-left: 15px;
            }
        }
    }

    .back {
        cursor: pointer;
        position: absolute;
        border-radius: 0;
        background: white;
        left: -70px;
        top: 0;
        margin-top: 0;
        padding: 20px;
        border: none;

        -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
        box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
    }
}
