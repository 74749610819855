@import "../theme.scss";

// Mixins
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.card {
    position: relative;

    background: white;
    z-index: 0;

    width: calc(25% - 30px);
    max-width: 450px;
    min-width: 245px;

    margin: 15px;
    margin-bottom: 45px;

    flex-grow: 10;
    border-radius: 9px;

    $spread: 2px;
    $blur: 20px;
    $opacity: 0.04;

    -webkit-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
    -moz-box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);
    box-shadow: 4px 2px $blur $spread rgba(0, 0, 0, $opacity);

    .image-wrapper {
        @include aspect-ratio(3, 1.6);
        background-size: cover;
        background-position: center;
        border-radius: 9px 9px 0 0;
        z-index: -10;
    }

    .body {
        padding: 20px;
        background-color: white;
        border-radius: 9px 0px 9px 9px;
        margin-top: -15px;
        z-index: 10;

        .top {
            position: relative;
            p {
                font-size: 0.95em;
                opacity: 0.6;
                margin-top: 4px;
                font-weight: bold;
            }

            .favorite {
                position: absolute;
                top: 0px;
                right: 5px;
                cursor: pointer;
                img {
                    height: 20px;
                }
                .active-img {
                    filter: invert(49%) sepia(42%) saturate(4789%) hue-rotate(331deg) brightness(108%) contrast(91%);
                }
            }
        }

        .attributes {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "delivery takeAway";

            .attribute {
                background-color: lighten($primary, 25);
                color: darken($primary, 5);
                width: 80%;
                
                border-radius: 5000px;
                padding: 10px;
                text-align: center;
                font-size: 0.9em;
            }
            #delivery{
                grid-area: delivery;
                justify-self: start;
            }
            #takeAway{
                grid-area: takeAway;
                justify-self: end; 
            }
            
        }

        .content {
            font-weight: 100;
            margin: 10px 0;
            line-height: 1.5em;
        }

        .opening {
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;

            .circle {
                display: inline-block;
                width: 10px;
                margin-top: 3px;
                height: 10px;
                background-color: gray;
                margin-right: 5px;
                border-radius: 5000px;
                &.open {
                    background-color: #c0e6ab;
                }
            }
            p {
                font-size: 0.9em;
                font-weight: bold;
                display: inline-block;
            }
        }
        padding-bottom: 40px;
    }
    .cta {
        position: absolute;
        bottom: -20px;
        width: 100%;
        display: flex;
        justify-content: center;
        
        .clicked-container {
            width: 80%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "phone menu";
            grid-column-gap: 10px;
        }
        .phone{
            grid-area: phone;
        }
        .menu{
            grid-area: menu;
        }
        .moreBtn{
            width: 100%;
        }

        button {
            border: none;          
            background-color: $primary;
            border-radius: 50000px;
            padding: 10px 30px;
            margin: 0 auto;
            color: white;
            font-weight: bold;
            position: relative;

            
            &:focus {
                outline: none;
            }
            cursor: pointer;
            .more{
                width: 100%;
                text-align: center;
            }
    
            .leaf{
                position: absolute;
                right: 11px;
                height: 40%;
            }
            .phoneImg{
                position: absolute;
                left: 11px;
                top: 13px;
                height: 30%;
            }
        }
    }

    &.empty {
        opacity: 0;
    }
}
