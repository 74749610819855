@import "../theme.scss";

.input_item {
    width: 100%;
    position: relative;
    display: block;
    input.rc-time-picker-input {
        margin-top: 0px;
    }
    
    .time-error {
        input.rc-time-picker-input {
            border: 0.5px solid red !important;
        }
    }
    
    .remove-button {
        position: absolute;
        border: none;
        background: none;
        right: 0;
        top: 20px;
        cursor: pointer;
    }

    &.short {
        input {
            width: calc(100% - 75px);
        }
    }
}    

