@import "./normalize.scss";
@import url("https://fonts.googleapis.com/css?family=Overpass:300,400,700&display=swap");

$primary: #F26664;
$background: #fbfbfb;
$muted: #727272;

$content-width: 1365px;
$mobile-width: 400px;
$tablet-width: 800px;

$form-width: 470px;

* {
    font-family: "Overpass", sans-serif !important;
}

a {
    text-decoration: none;
    color: auto;
}

body {
    background: $background;
}

*:focus {
    outline: none;
}