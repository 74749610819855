@import "../theme.scss";

/* Customize the label (the checkbox-container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 1rem;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: $primary;
        }
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;

        &:hover {
            background-color: lighten($primary, 20);
        }
    }

    .error {
        position: absolute;
        bottom: -15px;
        left: 38px;
        color: red;
        font-size: 0.95em;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .label {
        cursor: pointer;
    }
    
    p {
        margin: 5px;
        cursor: default;
    }
    
    a {
        color: $primary;
    }
}
